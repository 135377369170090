<template>
  <div>
     <v-container>
      <v-layout wrap>
        <v-flex sm12 md6 offset-md3>
          <v-card elevation="4" light tag="section" class="center">
            <v-card-title>
                <v-img :alt="platformName" class="ml-3" contain height="90px" position="center" src="https://www.vierp.in/logo/edu+camp-01.png"></v-img>
            </v-card-title>
            <center>
                <h5>Library Management System Super Admin Login</h5>
            </center>
            <v-divider></v-divider>
            <v-card-text>
              <v-form>
                <v-text-field
                    outline
                    placeholder="Username"
                    type="text"
                    v-model="username"></v-text-field>
                <v-text-field
                    outline
                    hide-details
                    placeholder="Password"
                    type="password"
                    v-model="password"></v-text-field>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
                <center>
                    <v-btn color="info" @click="login" style="margin-bottom :20px;" >
                        <i class="fa fa-lock"></i>  
                         Login
                    </v-btn>
                    <div class="redirection"><p  @click="redirection">Forgot Password</p></div>
                </center>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div> <!-- /. root div -->
</template>
<script>
import axios from 'axios'
export default {
  name: "SuperAdminLogin",
  components:{},
  data: () => ({
      username:null,
      password:null,
      msg:null,
  }),
  mounted() {

       // this.message = localStorage.getItem("login-page-msg");
        //console.log("login-page-msg>>" + localStorage.getItem("login-page-msg"));

        //this.$store.commit('setquick_links', false);
        localStorage.clear();
    },
  methods: {
    login(){
        const data={
            uid:this.username,
            password:this.password,
        };
        axios.post('/SuperAdmin/superadminLogin',data).then(res=>{
            localStorage.setItem("EPS-token", res.data.token);
            localStorage.setItem("EPS-uid", res.data.uid);
            
            if(res.data.status=='YES')
            {
               
                this.$router.push("/super-admin-home");
            }else{
                this.showsnackbar("Please check your credentials!!!", "error", 5000);
            }
        })
    },
    redirection() {
            console.log("inside redirection function")
            this.$router.push({ name: 'forgot-password', target: "blank" })
            localStorage.setItem("redirection", "1");
    },
    showsnackbar(msg, color, to) {
        this.$store.dispatch("setSnackbar", {
            showing: true,
            timeout: to,
            color: color,
            text: msg,
        });
    },
  },
  watch: {},
};
</script>
<style scoped>

@media (min-width: 320px) and (max-width: 767.98px) { 

}
@media (min-width: 768px) and (max-width: 991.98px) {

}
@media (min-width: 992px) and (max-width: 1024px) { 
}
.center {
  margin: auto;
  width: 100%;
  border: 3px solid #73AD21;
  padding: 0px;
}
.redirection {
    color: grey;
    font-size: 15px;
    text-decoration: underline;
}

.redirection:hover {
    cursor: -webkit-grab;
    cursor: pointer;
    color: blue;
}
</style>
